












import { Component, Mixins } from 'vue-property-decorator'

import { NavbarSimple } from '../../../../front/shared/organisms/Navbar'
import { AuthMixin } from '../../../../front/auth/shared'
import { AbstractModuleUi } from '../../../abstract/ui'
import { NavbarModule } from '../../Navbar.contracts'
import { navbarContentFactory } from '../../Navbar.factory'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 */
@Component({
  name: 'SimpleNavbar',
  components: { NavbarSimple }
})
export class SimpleNavbar extends Mixins<AbstractModuleUi<NavbarModule>, AuthMixin>(AbstractModuleUi, AuthMixin) {
  protected defaultContent = navbarContentFactory()

  /**
   * @override
   */
  public get shouldRender (): boolean {
    return this.content && Object.keys(this.content).length > 0
  }
}

export default SimpleNavbar
